<template>
  <div>
    <!-- <v-spacer /> -->
    <v-row>
      <v-spacer />
      <v-file-input
        id="input-image-file"
        accept="image/*"
        label="File input"
        filled
        prepend-icon="mdi-camera"
        @change="inputImage"
      />
      <v-btn
        id="btn-download"
        class="menu-item border"
        icon
        @click="downloadImage()"
      >
        <v-icon>mdi-download</v-icon>
      </v-btn>
      <v-spacer />
      <p style="display: none;">
        <canvas id="preview" />
      </p>
    </v-row>

    <tui-image-editor
      id="imageEditor"
      ref="tuiImageEditor"
      scoped
      :include-ui="useDefaultUI"
      :options="options"
    />
    <v-chip-group>
      <v-chip
        dark
        color="green"
        @click="addCopyright()"
      >
        {{ $t("message.concept_tool_copyright") }}
      </v-chip>

      <v-chip
        dark
        color="green"
      >
        <v-switch
          v-model="auto_name"
          :label="$t('message.concept_tool_auto_name')"
        ></v-switch>
      </v-chip>
    </v-chip-group>
    <v-data-iterator
      sort-by="type_jp"
      :sort-desc="sortDesc"
      :items="items"
      :search="search"
      :footer-props="{ 'items-per-page-options': [100, 200, 400]}"
      :items-per-page="100"
      :page="page"
      :options="options"
    >
      <template v-slot:header>
        <v-toolbar
          dark
          color="gray darken-3"
          class="mb-1"
        >
          <v-text-field
            v-model="search"
            clearable
            flat
            solo-inverted
            hide-details
            prepend-inner-icon="mdi-magnify"
            :label="$t('message.search_for_furniture')"
          />
          <template v-if="$vuetify.breakpoint.mdAndUp">
            <v-spacer />
            <v-btn-toggle
              v-model="sortDesc"
              mandatory
            >
              <v-btn
                large
                depressed
                color="gray"
                :value="false"
              >
                <v-icon>mdi-arrow-up</v-icon>
              </v-btn>
              <v-btn
                large
                depressed
                color="gray"
                :value="true"
              >
                <v-icon>mdi-arrow-down</v-icon>
              </v-btn>
            </v-btn-toggle>
          </template>
        </v-toolbar>
        <v-chip-group>
          <v-chip
            v-for="(furniture_type, index) in typeArray"
            :key="index"
            dark
            color="#e91e63"
            @click="updateItems(furniture_type)"
          >
            {{ furniture_type }}
          </v-chip>
        </v-chip-group>
      </template>

      <template v-slot:default="props">
        <v-container
          id="furniture-view"
          fluid
          tag="section"
        >
          <v-row
            justify="center"
          >
            <v-col
              v-for="item in props.items"
              :key="item.name"
              sm="3"
              md="2"
              lg="2"
            >
              <v-card
                class="mt-4 text-center"
                min-width="100px"
                max-width="300px"
              >
                <v-img
                  :src="item.image_path"
                />

                <v-card-text class="text-center">
                  <h6 class="text-h5 mb-3 text--primary">
                    {{ item.name }}
                  </h6>
                  <h4 class="text-h6 mb-2 text--secondary">
                    {{ item.type }}
                  </h4>

                  <p class="text--secondary">
                    {{ item.user_description }}
                  </p>
                  <v-text-field
                    v-model="item.num"
                    :label="$t('message.quantity')"
                    solo
                  />
                  <v-btn
                    class="mr-0"
                    color="primary"
                    rounded
                    @click="makeIcon(item.image_path,item.name,item.num,auto_name)"
                  >
                    {{ $t("message.concept_tool_add_icon") }}
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </v-data-iterator>
  </div>
</template>

<script>
// ライブラリのインポート
  import axios from 'axios'
  import { ImageEditor } from '@toast-ui/vue-image-editor'

  // アイコンのインポート
  import iconA from 'tui-image-editor/dist/svg/icon-a.svg'
  import iconB from 'tui-image-editor/dist/svg/icon-b.svg'
  import iconC from 'tui-image-editor/dist/svg/icon-c.svg'
  import iconD from 'tui-image-editor/dist/svg/icon-d.svg'

  // ライブラリ側で用意しているスタイルの読み込み
  import 'tui-image-editor/dist/tui-image-editor.css'

  // ダウンロード機能を使う際はインポートする
  import 'file-saver'

  // ロケールの設定。自分で日本語に訳して入れていく
  const localeJa = {
    // Load: 'ファイル選択',
    // Download: 'ダウンロード',
    // Apply: '適用',
    // Arrow: '矢印',
    // 'Arrow-2': '矢印2',
    // 'Arrow-3': '矢印3',
  }
  // 表示される各要素のスタイルを指定
  var theme = {
    'common.bi.image': '',
    'common.bisize.width': '0px',
    'common.bisize.height': '0px',
    'common.backgroundImage': 'none',
    'common.backgroundColor': '#1e1e1e',
    'common.border': '0px',

    // header
    'header.backgroundImage': 'none',
    'header.backgroundColor': 'transparent',
    'header.border': '0px',

    // load button
    'loadButton.backgroundColor': '#fff',
    'loadButton.border': '1px solid #ddd',
    'loadButton.color': '#222',
    'loadButton.fontFamily': "'Noto Sans', sans-serif",
    'loadButton.fontSize': '12px',
    'loadButton.display': 'none',
    // download button
    'downloadButton.backgroundColor': '#fdba3b',
    'downloadButton.border': '1px solid #fdba3b',
    'downloadButton.color': '#fff',
    'downloadButton.fontFamily': "'Noto Sans', sans-serif",
    'downloadButton.fontSize': '12px',
    'downloadButton.display': 'none',

    // main icons
    'menu.normalIcon.color': '#8a8a8a',
    'menu.activeIcon.color': '#555555',
    'menu.disabledIcon.color': '#434343',
    'menu.hoverIcon.color': '#e9e9e9',
    'menu.iconSize.width': '24px',
    'menu.iconSize.height': '24px',

    // submenu icons
    'submenu.normalIcon.color': '#8a8a8a',
    'submenu.activeIcon.color': '#e9e9e9',
    'submenu.iconSize.width': '32px',
    'submenu.iconSize.height': '32px',

    // submenu primary color
    'submenu.backgroundColor': '#1e1e1e',
    'submenu.partition.color': '#3c3c3c',

    // submenu labels
    'submenu.normalLabel.color': '#8a8a8a',
    'submenu.normalLabel.fontWeight': 'lighter',
    'submenu.activeLabel.color': '#fff',
    'submenu.activeLabel.fontWeight': 'lighter',

    // checkbox style
    'checkbox.border': '0px',
    'checkbox.backgroundColor': '#fff',
    // 'checkbox.border': '1px solid #ccc',
    // 'checkbox.backgroundColor': '#fff',
    
    // range style
    'range.pointer.color': '#fff',
    'range.bar.color': '#666',
    'range.subbar.color': '#d1d1d1',

    'range.disabledPointer.color': '#414141',
    'range.disabledBar.color': '#282828',
    'range.disabledSubbar.color': '#414141',

    'range.value.color': '#fff',
    'range.value.fontWeight': 'lighter',
    'range.value.fontSize': '11px',
    'range.value.border': '1px solid #353535',
    'range.value.backgroundColor': '#151515',
    'range.title.color': '#fff',
    'range.title.fontWeight': 'lighter',

    // colorpicker style
    'colorpicker.button.border': '1px solid #1e1e1e',
    'colorpicker.title.color': '#fff',

    // 'tui-image-editor-container.tui-image-editor-submenu.white-space': 'normal',

    // 共通のスタイル
    // 'common.bi.image': '',
    // 'common.bisize.width': '0px',
    // 'common.bisize.height': '0px',
    // 'common.backgroundImage': 'none',
    // 'common.backgroundColor': '#FFFFFF',
    // 'common.border': '0px',
    // // ヘッダー
    // 'header.backgroundImage': 'none',
    // 'header.backgroundColor': 'transparent',
    // 'header.border': '0px',
    // // ファイルアップロードボタンのスタイル
    // 'loadButton.backgroundColor': '#fff',
    // 'loadButton.border': '1px solid #ddd',
    // 'loadButton.color': '#222',
    // 'loadButton.fontFamily': 'NotoSans, sans-serif',
    // 'loadButton.fontSize': '12px',
    // 'loadButton.display': 'none',
    // // ダウンロードボタンのスタイル
    // 'downloadButton.backgroundColor': '#fdba3b',
    // 'downloadButton.border': '1px solid #fdba3b',
    // 'downloadButton.color': '#fff',
    // 'downloadButton.fontFamily': 'NotoSans, sans-serif',
    // 'downloadButton.fontSize': '12px',
    // 'downloadButton.display': 'none',
    // // メインアイコン
    // 'menu.normalIcon.path': iconD,
    // 'menu.activeIcon.path': iconB,
    // 'menu.disabledIcon.path': iconA,
    // 'menu.hoverIcon.path': iconC,
    // // サブメニューアイコン
    // 'submenu.normalIcon.path': iconD,
    // 'submenu.activeIcon.path': iconC,
    // // submenu primary color
    // 'submenu.backgroundColor': '#ffffff',
    // 'submenu.partition.color': '#858585',
    // // サブメニュー
    // 'submenu.normalLabel.color': '#858585',
    // 'submenu.normalLabel.fontWeight': 'lighter',
    // 'submenu.activeLabel.color': '#000',
    // 'submenu.activeLabel.fontWeight': 'lighter',
    // // チェックボックス
    // 'checkbox.border': '1px solid #ccc',
    // 'checkbox.backgroundColor': '#fff',
    // // 切り取り
    // 'range.pointer.color': '#000',
    // 'range.bar.color': '#666',
    // 'range.subbar.color': '#d1d1d1',
    // 'range.value.color': '#000',
    // 'range.value.fontWeight': 'lighter',
    // 'range.value.fontSize': '11px',
    // 'range.value.border': '1px solid #353535',
    // 'range.value.backgroundColor': '#151515',
    // 'range.title.color': '#000',
    // 'range.title.fontWeight': 'lighter',
    // // colorpicker
    // 'colorpicker.button.border': '0px',
    // 'colorpicker.title.color': '#fff',
  }

  export default {
    name: 'ImageEditor',
    components: {
      'tui-image-editor': ImageEditor,
    },
    props: {},
    data () {
      return {
        items: [],
        rawItems: [],
        id: '',
        sortDesc: true,
        search: '',
        page: 1,
        typeArray: ['All', '内装建材(内壁)', '内装建材(天井照明)', '調度品', '調度品(台座)', '調度品(卓上)', '調度品(壁掛)', '内装建材(床)', '調度品(敷物)', '絵画'],
        useDefaultUI: true,
        auto_name: 1,
        options: {
          cssMaxWidth: document.documentElement.clientWidth - 30,
          cssMaxHeight: '700',
          selectionStyle: {
            cornerSize: 20,
          },
          includeUI: {
            loadImage: {
              path: 'concepttool.png',
              name: 'SampleImage',
            },
            // 表示メニューの設定。デフォルトでは全て表示される
            // menu: ["flip", "crop", "rotate", "filter"],
            // コンポーネント生成時に選択されるメニューの指定
            initMenu: '',
            // メニューバーの位置の指定
            menuBarPosition: 'bottom',
            uiSize: {
              width: '100%',
              height: '80vh',
            },
            theme: theme,
            locale: localeJa,
          },
        },
      }
    },
    created: function () {
      switch (this.$i18n.locale) {
        case 'ja':
          axios.get(`../../furniture_ja.json?timestamp=${new Date().getTime()}`).then(res => {
            this.items = res.data
            this.rawItems = res.data
            this.typeArray = ['All', '調度品', '調度品(台座)', '調度品(卓上)', '調度品(壁掛)', '調度品(敷物)', '内装建材(内壁)', '内装建材(天井照明)', '内装建材(床)']
            for (const item of this.items) {
              item.num = ''
            }
          })
          break
        case 'en':
          axios.get(`../../furniture_en.json?timestamp=${new Date().getTime()}`).then(res => {
            this.items = res.data
            this.rawItems = res.data
            this.typeArray = ['All', 'Furnishing', 'Table', 'Tabletop', 'Wall-mounted', 'Rug', 'Interior Wall', 'Ceiling Light', 'Flooring']
            for (const item of this.items) {
              item.num = ''
            }
          })
          break
        case 'ko':
          axios.get(`../../furniture_ko.json?timestamp=${new Date().getTime()}`).then(res => {
            this.items = res.data
            this.rawItems = res.data
            this.typeArray = ['All', '가구', '소품(받침대)', '소품(탁상)', '소품(벽걸이)', '소품(깔개)', '내장 건축제(내벽)', '내장 건축제(천장 조명)', '내장 건축제(바닥)']
            for (const item of this.items) {
              item.num = ''
            }
          })
          break
        default:
          axios.get(`../../furniture_en.json?timestamp=${new Date().getTime()}`).then(res => {
            this.items = res.data
            this.rawItems = res.data
            this.typeArray = ['All', 'Furnishing', 'Table', 'Tabletop', 'Wall-mounted', 'Rug', 'Interior Wall', 'Ceiling Light', 'Flooring']
            for (const item of this.items) {
              item.num = ''
            }
          })
          break
      }
    },
    methods: {
      updateItems (furnitureType) {
        if (furnitureType !== 'All') {
          console.log(furnitureType)
          this.items = this.rawItems.filter(item => item.type === furnitureType)
        } else { this.items = this.rawItems }
      },
      makeIcon: function (imagePath, name, n,auto_name) {
        console.log('n:' + n)
        console.log(imagePath)
        var url = '../../' + imagePath
        console.log(url)

        var image = new Image()
        image.src = url
        image.onload = function () {
          // 画像ロードが完了してからキャンバスの準備をする
          var canvas = document.getElementById('preview')
          var ctx = canvas.getContext('2d')


          console.log('name_width:' + ctx.measureText(name).width)
          // キャンバスのサイズを画像サイズに合わせる
          canvas.width = 106
          canvas.height = 106
          var y_sub = -15
          if(auto_name==1) {
            y_sub = 40
            canvas.height = canvas.height + 54
            console.log('name_width:' + ctx.measureText(name).width)
            if ((ctx.measureText(name).width*2 > canvas.width)){
              canvas.width = ctx.measureText(name).width*2+5
            }          
          }
          // 文字のスタイルを指定
          ctx.font = '20px Noto Sans Mono'
          ctx.lineWidth = '6'
          ctx.lineJoin = 'miter'
          ctx.miterLimit = '5'
          // キャンバスに画像を描画（開始位置0,0）
          ctx.drawImage(image, 0, 0)
          // console.log('auto_name:' + auto_name)
          // 文字の配置を指定（左上基準にしたければtop/leftだが、文字の中心座標を指定するのでcenter
          ctx.textBaseline = 'bottom'
          ctx.textAlign = 'left'
          console.log('name_width:' + ctx.measureText(name).width)
          // 座標を指定して文字を描く（座標は画像の中心に）
          var y = (canvas.height) - 25
          ctx.fillStyle = '#ffffff'
          if (auto_name==1) {
            ctx.strokeText(name, 0, y)
            ctx.fillText(name, 0, y)
          }
          ctx.textBaseline = 'bottom'
          ctx.textAlign = 'right'
          ctx.strokeText(n, 100, y - y_sub)
          ctx.fillText(n, 100, y - y_sub)
          _this.addIcon(canvas.toDataURL())
        }
        const _this = this
      },

      addIcon: function (url) {
        this.$refs.tuiImageEditor.invoke('addImageObject', url).then(objectProps => {
          console.log(objectProps.id)
          this.id = objectProps.id
          console.log(this.id)
        })
      },
      addCopyright: function () {
        this.$refs.tuiImageEditor.invoke('addText', '(C) SQUARE ENIX CO., LTD. All Rights Reserved.', {
          styles: {
            fill: '#fff',
            fontSize: 20,
            fontWeight: 'bold'
          }})
        },
            // setTimeout(() => {
      //   console.log(this.id)
      //   this.$refs.tuiImageEditor.invoke('setObjectProperties', (this.id, {
      //     left: 100,
      //     top: 100,
      //     width: 200,
      //     height: 200,
      //     opacity: 0.5,
      //   }))
      // }, 100)
      base64ToBlob: function (data) {
        var mimeString = ''
        var raw, uInt8Array, i, rawLength
        var rImageType = /data:(image\/.+);base64,/

        raw = data.replace(rImageType, function (header, imageType) {
          mimeString = imageType

          return ''
        })

        raw = atob(raw)
        rawLength = raw.length
        uInt8Array = new Uint8Array(rawLength); // eslint-disable-line

        for (i = 0; i < rawLength; i += 1) {
          uInt8Array[i] = raw.charCodeAt(i)
        }

        return new Blob([uInt8Array], { type: mimeString })
      },
      inputImage: function (event) {
        console.log(event)
        var file
        var supportingFileAPI = !!(window.File && window.FileList && window.FileReader)

        if (!supportingFileAPI) {
          alert('This browser does not support file-api')
        }

        file = event
        this.$refs.tuiImageEditor.invoke('loadImageFromFile', file).then(function (result) {
          console.log(result)
        })
        this.$refs.tuiImageEditor.invoke('clearUndoStack')
      },

      downloadImage: function () {
        var supportingFileAPI = !!(window.File && window.FileList && window.FileReader)
        // var imageName = this.imageEditor.getImageName()
        var imageName = this.$refs.tuiImageEditor.invoke('getImageName')
        // var dataURL = this.imageEditor.toDataURL()
        var dataURL = this.$refs.tuiImageEditor.invoke('toDataURL')
        var blob, type, w

        if (supportingFileAPI) {
          blob = this.base64ToBlob(dataURL)
          type = blob.type.split('/')[1]
          if (imageName.split('.').pop() !== type) {
            imageName += '.' + type
          }

          // Library: FileSaver - saveAs
          saveAs(blob, imageName); // eslint-disable-line
        } else {
          alert('This browser needs a file-server')
          w = window.open()
          w.document.body.innerHTML = '<img src="' + dataURL + '">'
        }
      },

    },
  }
</script>
